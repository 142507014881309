
import { ref, reactive, inject, Ref, computed } from 'vue';
import AddUrlDialog from './AddUrlDialog.vue';
import _ from 'lodash';
// import { getServiceApiList } from "@/api/servers/index";
import { getServiceModelList } from '@/api/schema/model';
import { exportUrlGatewayList, getModelApiList } from '@/api/mergeDeploy/index';
import { parseList } from '../../api/util';
import { SYSTEM_APIS } from '../../api/config';
import { ElMessage } from 'element-plus';

export default {
  name: '',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddUrlDialog,
  },
  setup(props: any, ctx: any) {
    const dialogFormVisible = ref(false as boolean);
    const committing = ref(false as boolean);
    const formRef = ref(null as any);
    const interfaceList: Ref<Array<any>> = ref([]);
    const modelList: Ref<Array<any>> = ref([]);
    const whiteUrlInfos: Ref<Array<any>> = ref([]);
    const urlRules: Ref<Array<any>> = ref([]);
    const serviceId = inject('serviceId') as number;
    const refAddUrlDialog = ref(null as any);
    const currentServiceId = ref(0 as number);
    const currentModelId = ref(0 as number);

    const serverList = inject('serverList') as Array<any>;

    const serverListAll = computed(() => {
      const list = serverList.filter((item: any) => item.appearance !== 3);
      return list;
    });
    const formLabelWidth = '140px';

    const form = reactive({
      serviceUrl: '',
      modelName: '',
      interface: [] as any[],
    });

    const rules = {
      serviceUrl: [
        {
          required: true,
          message: '请选择服务',
          trigger: 'blur',
        },
      ],
      modelName: [
        {
          required: true,
          message: '请选择模型',
          trigger: 'blur',
        },
      ],
    };

    const openDialog = () => {
      dialogFormVisible.value = true;
      whiteUrlInfos.value = [];
      urlRules.value = [];
      modelList.value = [];
      interfaceList.value = [];
      currentServiceId.value = 0;
      currentModelId.value = 0;
      // formRef.value.resetFields();
      if (formRef.value) formRef.value.resetFields();
    };

    const handleSubmit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return;
      }
      if (form.interface.length === 0 && urlRules.value.length === 0) {
        ElMessage.info('请选择接口');
        return;
      }
      committing.value = true;
      const saveData = {
        serviceId: currentServiceId.value,
        gatewayServiceId: serviceId,
        modelId: currentModelId.value,
        whiteUrlInfos: [...whiteUrlInfos.value, ...urlRules.value],
      };
      try {
        const { code } = await exportUrlGatewayList(saveData);
        console.log(code);

        if (code === 0) {
          // emit("change");
          whiteUrlInfos.value = [];
          urlRules.value = [];
          ElMessage.success('新增成功');
          ctx.emit('setUrl', 0);
        }
      } catch (e) {
        console.log(e);
      } finally {
        committing.value = false;
      }
      dialogFormVisible.value = false;
    };

    const selectService = async (serviceId: string) => {
      try {
        form.modelName = '';
        form.interface = [];
        const { data } = await getServiceModelList({
          serviceId,
        });

        modelList.value = _.cloneDeep(data.models);
        currentServiceId.value = Number(serviceId);
      } catch (error) {}
    };

    const selectModel = async (modelId: string) => {
      try {
        form.interface = [];
        const { data } = await getModelApiList({
          serviceId: currentServiceId.value,
          modelId,
        });

        const rowList = parseList(data || []);

        const apiList = [...rowList.reverse(), ...SYSTEM_APIS.filter((i) => !i.reserved)];
        interfaceList.value = _.cloneDeep(apiList);
        currentModelId.value = Number(modelId);
      } catch (error) {}
    };

    const addUrl = () => {
      refAddUrlDialog.value.openDialog();
    };

    const interfaceChange = (val: any) => {
      const url = val[val.length - 1];
      whiteUrlInfos.value.push({ url, isAuth: 1 });
    };

    const setUrl = (result: any) => {
      urlRules.value = JSON.parse(JSON.stringify(urlRules.value));
      urlRules.value.splice(0, 0, { ...result });
    };

    const delUrlRules = (index: number) => {
      urlRules.value.splice(index, 1);
    };

    return {
      dialogFormVisible,
      openDialog,
      formLabelWidth,
      form,
      rules,
      formRef,
      handleSubmit,
      serverListAll,
      selectService,
      selectModel,
      interfaceList,
      refAddUrlDialog,
      addUrl,
      interfaceChange,
      setUrl,
      urlRules,
      delUrlRules,
      committing,
      modelList,
    };
  },
};


import useButtonUtils from './utils/service-detail-utils';
import { ref, Ref, watch, provide, computed, onBeforeUnmount, getCurrentInstance } from 'vue';
import {
  getServiceList,
  getServiceById,
  updateServiceStatus,
  // startService,
} from '@/api/servers';
import { rebuildGateway } from '@/api/mergeDeploy';
import { getAllTags } from '@/api/settings/tags';
import { getClassificationList } from '@/api/settings/classification';
import { getDataTypesAll } from '@/api/settings/data-types';
import { getShowBool } from '@/utils/permission-show-module';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { userInfo, userProjectList } from '@/layout/messageCenter/user-info';
import {
  statusMap,
  // computeStatusLabel,
  // statusColor,
} from '@/views/service-management/business-service/utils/service-status-map';
import {
  currentServiceIdForData,
  handleChangeApply,
  thenRefresh,
  serverInfo,
  serverList,
} from './utils/service-detail-data';
// import _ from "lodash/fp";
import { getServiceShowName } from '../components/utils';
import { currentServiceSourceAllowed, useCheckRefrenceService } from './utils/permisson';
import { useForceUpdate } from './components/useVerionInput';
import DomainConfig from '@/views/service-management/business-service/components/domainConfig/DomainConfig.vue';
import LogDialog from './components/LogDialog.vue';
import useTenant from '@/views/tenant-management/useTenant';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
import { removeEnvStorage } from '@/utils/env-storage';
import { useRoute } from 'vue-router';
import ServerConfigInfo from './components/ServerConfigInfo.vue';
import ExterNetService from './components/ExterNetService.vue';
import { openDomainDialog } from '@/views/service-management/business-service/components/domainConfig/dataCenter';

interface RefDialog {
  openDialog: Function;
  [attr: string]: any;
}

export default {
  name: 'gwServiceDetail',
  components: {
    ServiceName,
    DomainConfig,
    LogDialog,
    ServerConfigInfo,
    ExterNetService,
  },
  setup() {
    // 获取路由信息
    const { serviceId, envName, envId, env } = useEnvAndServiceId() as any;
    // 当前服务ID
    const currentServiceId = ref(serviceId);
    currentServiceIdForData.value = serviceId;
    const minHeight = ref(450 as any);

    const logDialogRef = ref(null as any);
    const erdRef = ref(null as any);
    const editStatus = ref(0 as any);
    const refServerConfigInfo = ref(null as any);
    provide('editStatus', editStatus);
    provide('envId', envId);
    provide('serviceId', currentServiceId.value);

    const getLog = () => {
      logDialogRef.value.handleOpenDialog();
    };
    const domainRef = ref<InstanceType<typeof DomainConfig>>();
    const { tenant, fetchTenantInfo } = useTenant();
    if (!tenant) {
      fetchTenantInfo();
    }
    const { buttons } = useButtonUtils({
      applyChange: async () => await handleChangeApply(),
      getLog,
      tenant,
      domainRef,
    });

    // 获取this指针
    const { proxy } = getCurrentInstance() as any;

    // 是否显示底部抽屉
    const isShowDownDrawer = ref(true);

    const computedHeight = computed(() => (isShowDownDrawer.value ? 'calc(95% - 400px)' : '95%'));

    const { renderKey, forceUpdate: forceUpdateBaseInfo } = useForceUpdate();

    // 属性列表是否已打开
    const isOpenProperties = ref(false);

    // 获取组件实例
    // const instance = getCurrentInstance();
    // 提示信息
    // function msgTips(type: string, content: string) {
    //   (instance as any).proxy.$message({
    //     type,
    //     message: content,
    //   });
    // }
    // 发版前提示状态
    // const relDiavisible = ref(false);

    // 发版前依赖未更新返回数据
    // const releseList = ref([]);

    const activeName = ref('serviceConfig');

    const handleClick = (tab: any, event: Event) => {
      console.log(tab, event);
    };

    const getServerList = async () => {
      const { data } = await getServiceList({ all: true });
      data.rows.forEach((x: any) => {
        // eslint-disable-next-line no-param-reassign
        x.shortName = x.name ? getServiceShowName(x.name) : '';
      });
      serverList.length = 0;
      serverList.push(...(data.rows || []));
    };

    getServerList();

    provide('serverList', serverList);
    const loading = ref(true);

    // 服务详情信息
    const { isRefrenceService } = useCheckRefrenceService(serverInfo);

    // 获取服务详情
    const fetchTimer = ref();
    const getServerInfo = async (useLoading = true) => {
      if (useLoading) {
        loading.value = true;
      }
      clearTimeout(fetchTimer.value);
      try {
        const { data } = await getServiceById({ id: currentServiceId.value });
        const { dependencies, ...info } = data;
        const dependencyList = dependencies.map((i: any) => [i.dependencyServiceName, i.dependencyServiceVersion]);
        serverInfo.value = {
          ...info,
          dependencies: dependencyList,
          serviceDependencies: dependencies,
        };
        editStatus.value = serverInfo.value.editStatus;
        proxy.$forceUpdate();
        fetchTimer.value = setTimeout(() => getServerInfo(false), 5000);
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    getServerInfo();

    const tags: any[] = [];

    // 获取所有标签
    const getTags = async () => {
      const { data } = await getAllTags();
      tags.push(...(data || []));
    };

    getTags();

    const classifications: any[] = [];

    // 获取所有分类信息
    const getClassifications = async () => {
      const { data } = await getClassificationList();
      classifications.push(...(data || []));
    };

    getClassifications();

    // 获取所有字段类型
    const allTypes = ref([]);
    const initTypeOption = async () => {
      const { code, data } = await getDataTypesAll();
      if (code === 0) {
        allTypes.value = data;
      }
    };
    initTypeOption();

    // 服务状态
    const serverStatusInfo = ref({});
    const startBtn = ref(false as boolean);

    watch(serverInfo, () => {
      const { id } = serverInfo.value;
      const status = Number(serverInfo.value.status);
      currentServiceSourceAllowed.value = [1, 4].includes(serverInfo.value.serviceSource);
      const { stop: stopBtn } = buttons.value;
      const isDisabled = status === 10 || status === 20 || status === 30;
      stopBtn.disabled = isDisabled;
      startBtn.value = isDisabled || status === 11;

      switch (status) {
        case 0:
          startBtn.value = true;
          stopBtn.disabled = true;
          break;
        default:
          break;
      }

      if (status !== 21) {
        stopBtn.disabled = true;
      }

      const statusmaps = {
        0: '启动中',
        10: '变更中',
        11: '变更成功',
        20: '启动中',
        21: '启动成功',
        22: '启动失败',
        30: '停止中',
        31: '停止成功',
        32: '停止失败',
      };

      const statusColor = {
        0: '#FF9D00',
        10: '#FF9D00',
        11: '#0ABF5B',
        20: '#FF9D00',
        21: '#0ABF5B',
        22: '#E54545',
        30: '#FF9D00',
        32: '#E54545',
        31: '#0ABF5B',
      };
      serverStatusInfo.value = {
        label: (statusmaps as any)[status],
        color: (statusColor as any)[status],
      };
      if (status === 30) {
        updateServiceStatus([id]);
      }
    });

    watch(thenRefresh, () => {
      getServerInfo();
    });

    // 右侧组件名称
    const componentName = ref('');
    // 下侧组件名称
    const drawerName = ref('');

    // 打开服务配置
    const openConfigInfo = () => {
      if (drawerName.value === 'ServerConfigInfo') {
        drawerName.value = '';
      } else {
        drawerName.value = 'ServerConfigInfo';
      }
    };

    watch(isShowDownDrawer, (nn) => {
      if (!nn) {
        drawerName.value = '';
      }
    });

    // 切换服务

    const selectService = async (value: number) => {
      currentServiceId.value = value;
      let name = '';
      serverList.forEach((x: any) => {
        if (x.id === value) {
          name = x.name;
        }
      });
      componentName.value = '';
      isShowDownDrawer.value = false;
      let path = '';
      let query = null;

      console.log('env', env.toLocaleLowerCase(), envId);

      if (envId) {
        path = `/env/${env.toLocaleLowerCase()}/${envId}/service/${value}`;
        query = {
          env,
          envName,
        };
      } else {
        path = `/services/business/edit/${value}`;
        query = {
          detailName: name,
        };
      }
      proxy.$router.replace({
        path,
        query,
      });
      // setTimeout(() => {
      //   window.location.reload();
      // });
    };

    const logs = (res: any) => {
      console.log(res, 'this is log');
      return res;
    };

    const maskText = computed(() => {
      switch (serverInfo.value.status) {
        case 10:
          return '服务变更中, 请稍后...';
        case 11:
          return '服务变更成功, 请稍后...';
        case 20:
          return '服务启动中, 请稍后...';
        case 30:
          return '服务停止中, 请稍后...';
        default:
          return '';
      }
    });
    onBeforeUnmount(() => {
      clearTimeout(fetchTimer.value);
      removeEnvStorage();
    });

    const releaseRef: Ref<RefDialog | null> = ref(null);

    const drawerReadonlyAuth = computed(() => !getShowBool('add'));
    watch(
      () => serverInfo.value.id,
      () => {
        forceUpdateBaseInfo();
      },
    );

    const isCoding = computed(() => userInfo.value?.gitRepository === 'CODING');

    // 启动服务
    const handleConfirmStart = async () => {
      const gatewayInfos = envId
        ? JSON.parse(localStorage.getItem('gatewayenvInfos') || '')
        : JSON.parse(localStorage.getItem('gatewayInfos') || '');

      loading.value = true;
      try {
        const res = await rebuildGateway({
          gatewayId: gatewayInfos.id,
        });
        if (res.code === 0) {
          getLog();
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
      // }
    };

    //
    const route = useRoute();
    let serviceFullName = route.query.detailName;
    if (serviceFullName) {
      sessionStorage.setItem('serviceDetail_serviceFullName', serviceFullName.toString());
    } else {
      serviceFullName = sessionStorage.getItem('serviceDetail_serviceFullName');
    }

    const deliveryList = () => {
      refServerConfigInfo.value.deliveryList();
    };

    const gwStart = async () => {
      openDomainDialog();
    };

    return {
      isShowDownDrawer,
      computedHeight,
      currentServiceId,
      selectService,
      isOpenProperties,
      serverInfo,
      serverList,
      tags,
      classifications,
      buttons,
      serverStatusInfo,
      componentName,
      drawerName,
      openConfigInfo,
      logs,
      statusMap,
      maskText,
      userProjectList,
      getShowBool,
      getServerInfo,
      loading,
      releaseRef,
      isRefrenceService,
      drawerReadonlyAuth,
      renderKey,
      isCoding,
      logDialogRef,
      handleConfirmStart,
      domainRef,
      erdRef,
      envName,
      env,
      minHeight,
      activeName,
      handleClick,
      refServerConfigInfo,
      deliveryList,
      gwStart,
      startBtn,
    };
  },
};


import { ref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  name: '',
  props: {
    urlRules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: any, ctx: any) {
    const dialogFormVisible = ref(false as boolean);
    const formRef = ref(null as any);

    const formLabelWidth = '140px';

    const form = reactive({
      result: {
        url: '',
        isAuth: 1,
      },
    });

    const rules = {
      url: [
        {
          required: true,
          message: '请输入url',
          trigger: 'blur',
        },
      ],
    };

    const openDialog = (formObj = { url: '', isAuth: 1 }) => {
      dialogFormVisible.value = true;
      form.result = { ...formObj };
      if (formRef.value) formRef.value.resetFields();
    };

    const handleSubmit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return;
      }

      const info = props.urlRules?.find((item: any) => item.url === form.result.url);
      if (info) {
        ElMessage.error('该规则已经存在');
        return;
      }
      ctx.emit('setUrl', { ...form.result });
      dialogFormVisible.value = false;
    };

    return {
      dialogFormVisible,
      openDialog,
      formLabelWidth,
      form,
      rules,
      formRef,
      handleSubmit,
    };
  },
};

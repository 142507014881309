
import { inject, reactive, ref, Ref } from 'vue';
import { getGatewayList, updateAuthGateway, deletteUrlGateway, updateStatusGateway } from '@/api/mergeDeploy';
import AddUrlDialog from './AddUrlDialog.vue';
import ExportUrlDialog from './ExportUrlDialog.vue';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  name: '',
  components: {
    AddUrlDialog,
    ExportUrlDialog,
  },
  setup() {
    const envId = inject('envId') as number;
    const gatewayInfos = envId
      ? JSON.parse(localStorage.getItem('gatewayenvInfos') || '')
      : JSON.parse(localStorage.getItem('gatewayInfos') || '');

    const isNxterNet = ref(0 as number); // 是否允许外网访问
    isNxterNet.value = gatewayInfos.isExtranet; // 是否允许外网访问
    const refAddUrlDialog = ref(null as any);
    const serviceId = inject('serviceId') as number;
    const refExportUrlDialog = ref(null as any);
    const count = ref(1 as number);
    const query = reactive({
      pageNo: 1,
      pageSize: 10,
    });
    const tableData: Ref<Array<any>> = ref([]);

    const loading = ref(false as boolean);

    // 获取网关白名单列表
    const getGwLists = async (query: any) => {
      loading.value = true;
      try {
        const { data } = await getGatewayList({
          ...query,
          gateServiceId: serviceId,
        });
        tableData.value = [...data.gatewayWhiteUrlList];
        count.value = Number(data.count);
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };
    getGwLists(query);

    const handleSizeChange = (val: number) => {
      query.pageSize = val;
      query.pageNo = 1;
      getGwLists(query);
    };

    const handleCurrentChange = (val: number) => {
      query.pageNo = val;
      getGwLists(query);
    };

    // 导入白名单列表
    const exportUrl = () => {
      refExportUrlDialog.value.openDialog();
    };

    // 删除白名单
    const deleteUrl = (row: any) => {
      ElMessageBox.confirm(`确认删除url${row.url}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deletteUrlGateway(row.id);
          if (code === 0) {
            ElMessage.success('删除成功');
            getGwLists(query);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // 鉴权修改
    const authChange = async (data: any) => {
      try {
        const res = await updateAuthGateway({
          id: Number(data?.id),
          auth: data?.isAuth,
        });
        if (res.code !== 0) {
          data.isAuth = data?.isAuth === 0 ? 1 : 0;
        } else ElMessage.success('更新成功');
      } catch (error) {
        data.isAuth = data?.isAuth === 0 ? 1 : 0;
      }
    };

    // 允许外网访问change
    const nxterNetChange = async (status: number) => {
      const text = status === 0 ? `确认不允许外网访问？` : `确认允许外网访问？`;
      ElMessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            const { code } = await updateStatusGateway({
              serviceId,
              status,
            });
            if (code === 0) {
              gatewayInfos.isExtranet = status;
              localStorage.setItem('gatewayInfos', JSON.stringify(gatewayInfos));
            } else {
              isNxterNet.value = isNxterNet.value === 1 ? 0 : 1;
            }
          } catch (error) {
            isNxterNet.value = isNxterNet.value === 1 ? 0 : 1;
          }
        })
        .catch(() => {
          isNxterNet.value = isNxterNet.value === 1 ? 0 : 1;
        });
    };

    const setUrl = () => {
      getGwLists(query);
    };

    return {
      isNxterNet,
      tableData,
      exportUrl,
      deleteUrl,
      refAddUrlDialog,
      refExportUrlDialog,
      handleSizeChange,
      handleCurrentChange,
      query,
      count,
      authChange,
      nxterNetChange,
      setUrl,
      loading,
    };
  },
};
